import sanityClient from "@sanity/client";
import amediaConfig from "../amediaConfig/amediaConfig";

// TODO: use cdn for fetching greetings, but not for saving/fetching single greeting?

const optionsIssues = {
  projectId: "3rfhlrw4",
  // eslint-disable-next-line no-undef
  dataset: amediaConfig(window).dataset,
  // eslint-disable-next-line no-undef
  useCdn: amediaConfig(window).useCdn,
};

export default sanityClient(optionsIssues);
