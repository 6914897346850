import React from "react";
import { FormSpy } from "react-final-form";
import i18next from "i18next";

const WarningEngine = ({ products }) => (
  <FormSpy
    subscription={{ values: true }}
    onChange={({ values }) => {
      const product = products.find((p) => p._key === values.product_id);
      if (product) {
        let length = values.adText ? values.adText.length : 0;
        if (length > 149) {
          values.adText = values.adText.substring(0, 150);
          length = values.adText ? values.adText.length : 0;
        }
      }
    }}
  />
);

export default WarningEngine;
